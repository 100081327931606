<script setup>
import {ref, computed, onMounted, onBeforeUnmount} from "vue";
import {Peer} from "peerjs";
import axios from "axios";
import StreamPlayer from "@/components/StreamPlayer.vue";
import {useStore} from "@/store";
import {useRouter} from "vue-router/composables";
import CanvasDesk from "@/components/CanvasDesk.vue";

const router = useRouter();
const store = useStore();
const shareCanvas = ref(false);
const muteCanvas = ref(false);
const peercall = ref();
const peer = ref(new Peer());
const isCall = ref(false);
const callGo = ref(false);
const waitStart = ref(false);
const myStream = ref();
const otherStream = ref();
const JSONdata = ref({});
const connection = ref(null);

const toggleVideoStream = async (arg) => {
  if (arg === "rm") {
    try {
      navigator.mediaDevices
          .getUserMedia({audio: true, video: false})
          .then(function (stream) {
            myStream.value = stream;
            peercall.value.peerConnection
                .getSenders()[1]
                .replaceTrack(stream.getVideoTracks()[0]);
          });
    } catch (e) {
      console.log(e);
    }
  } else {
    try {
      navigator.mediaDevices
          .getUserMedia({audio: true, video: true})
          .then(function (stream) {
            myStream.value = stream;
            peercall.value.peerConnection
                .getSenders()[1]
                .replaceTrack(stream.getVideoTracks()[0]);
          });
    } catch (e) {
      console.log(e);
    }
  }
};
const toggleMicroStream = async (arg) => {
  if (arg === "rm") {
    try {
      navigator.mediaDevices
          .getUserMedia({audio: false, video: true})
          .then(function (stream) {
            myStream.value = stream;
            // console.log(peercall.value.peerConnection.getSenders());
            peercall.value.peerConnection
                .getSenders()[0]
                .replaceTrack(stream.getAudioTracks()[0]);
          });
    } catch (e) {
      console.log(e);
    }
  } else {
    try {
      navigator.mediaDevices
          .getUserMedia({audio: true, video: true})
          .then(function (stream) {
            myStream.value = stream;
            // peercall.peerConnection.getSenders()[1].replaceTrack(stream)
            peercall.value.peerConnection
                .getSenders()[0]
                .replaceTrack(stream.getAudioTracks()[0]);
          });
    } catch (e) {
      console.log(e);
    }
  }
};
const shareScreen = () => {
  try {
    navigator.mediaDevices.getDisplayMedia().then(function (stream) {
      myStream.value = stream;
      peercall.value.peerConnection
          .getSenders()[1]
          .replaceTrack(stream.getVideoTracks()[0]);
    });
  } catch (e) {
    console.log(e);
  }
};
const peerInit = () => {
  waitStart.value = true;
  peer.value = new Peer(store.getters.lessonData.btn.peerIds.student, {
    config: store.getters.lessonData.btn.userAuth,
  });
  peer.value.on("open", function (id) {
    console.log("open");
  });

  callToNode(store.getters.lessonData.btn.peerIds.teacher);
};
const callToNode = (peerId) => {
  //вызов
  navigator.mediaDevices
      .getUserMedia({
        audio: true,
        video: {
          width: {min: 320, ideal: 1100, max: 1200},
          height: {min: 220, ideal: 615, max: 615},
          facingMode: "user",
        },
      })
      .then((mediaStream) => {
        peercall.value = peer.value.call(peerId, mediaStream);
        peercall.value.on("stream", (stream) => {
          //нам ответили, получим стрим
          setTimeout(() => {
            otherStream.value = stream;
            waitStart.value = false;
          }, 1500);
        });
        myStream.value = mediaStream;
        connection.value = peer.value.connect(peerId);
        // on open will be launch when you successfully connect to PeerServer
        connection.value.on("open", function () {
          // conn.send("hi!");
        });
        connection.value.on("data", (data) => {
          // console.log(`received: ${data}`);
          if (data === "endLesson") {
            store.commit("showAlert", {
              show: true,
              type: "success",
              msg: "Занятие завершено, спасибо за урок!",
            });
            setTimeout(() => {
              router.push({name: "lessons"});
            }, 8000);
          } else if (data === "canvasGo") {
            shareCanvas.value = true;
          } else if (data === "canvasEnd") {
            shareCanvas.value = false;
          } else if (data === "canvasMute") {
            muteCanvas.value = true;
          } else if (data === "canvasUnmute") {
            muteCanvas.value = false;
          } else {
            JSONdata.value = JSON.parse(data);
          }
        });
      })
      .catch(function (err) {
        store.commit("showAlert", {
          show: true,
          type: "error",
          msg: "Доступ к камере запрещен. Проверьте настройки браузера/системы",
        });
      });
  callGo.value = true;
  isCall.value = true;
};
const JSONsend = (json) => {
  connection.value.send(json);
};
const sendImg = (data) => {
  connection.value.send(JSON.stringify(data))
};
onMounted(() => {
  peerInit();
});
onBeforeUnmount((n) => {
  router.go(n);
});
</script>

<template>
  <div class="call pb-10">
    <v-container>
      <h1 class="title-md mt-0">Видео-урок</h1>

      <v-row v-if="waitStart">
        <v-col cols="12">
          <div class="videobg">
            <div class="videobg__content">
              <img src="@/assets/images/loading.svg" alt=""/>
              <span>Дождитесь ответа от учителя</span>
            </div>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-alert type="success" :icon="false">
            <div class="d-flex align-center">
              <span>Ваш учитель:</span>
              <p class="font-weight-500 mb-0 ml-4">
                {{ store.getters.lessonData.btn.teacher }}
              </p>
            </div>
          </v-alert>
        </v-col>
      </v-row>
      <div v-if="callGo" class="stream">
        <StreamPlayer
            @toggleVideo="toggleVideoStream"
            @toggleMicro="toggleMicroStream"
            :absolute="shareCanvas"
            :myStream="myStream"
            :otherStream="otherStream"
        />
        <CanvasDesk
            v-if="shareCanvas"
            :JSONcanvas="JSONdata"
            @JSON="JSONsend"
            @sendImg="sendImg"
        />
      </div>
    </v-container>
  </div>
</template>

<style lang="scss" scoped>
.stream {
  position: relative;
}

.title-md {
  font-size: 32px !important;
  font-family: "Nunito", sans-serif !important;
  text-align: center;
}

.videobg {
  width: 1100px;
  height: 615px;
  background: url("@/assets/images/videobg.png") center / cover no-repeat;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 32px;
    height: 32px;
  }

  span {
    color: #727272;
    line-height: 150%;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    background: #fff;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0 8px 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    padding: 40px 50px;
  }
}
</style>
