<template>
  <v-app>
    <v-main>
      <landing-header v-if="!isAuth" @showLogin="showLogin"/>
      <profile-header v-if="isAuth"/>
      <div class="page">
        <router-view/>
      </div>
      <landing-footer/>
      <loader v-if="isLoading"/>
      <v-dialog
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        v-model="showLoginPopup"
      >
        <popup-login
          @changeTab="changeLoginType"
          :active-tab="activeTabLogin"
          @close="closeLoginPopup"
        />
      </v-dialog>
      <transition name="fade">
        <v-alert v-if="alert.show" class="global-alert" :type="alert.type" v-html="alert.msg"></v-alert>
      </transition>
    </v-main>
  </v-app>
</template>

<script>

import Loader from "@/components/Loader";
import {mapGetters, mapActions} from "vuex/dist/vuex.mjs";
import LandingHeader from "@/components/mainPage/LandingHeader";
import PopupLogin from "@/components/popups/PopupLogin";
import LandingFooter from "@/components/mainPage/LandingFooter";
import ProfileHeader from "@/components/profilePage/ProfileHeader";
import axios from "axios";
import {mapMutations} from "vuex";

export default {
  name: 'App',
  components: {ProfileHeader, LandingFooter, PopupLogin, LandingHeader, Loader},
  data() {
    return {
      activeTabLogin: 1,
    }
  },
  methods: {
    closeLoginPopup() {
      this.setShowLoginPopup({boolean: false})
    },
    showLogin(num) {
      this.setShowLoginPopup({boolean: true})
      this.activeTabLogin = num;
    },
    changeLoginType(num) {
      this.activeTabLogin = num;
    },
    onResize() {
      if (window.innerWidth > 1200) {
        this.setIsDesktop({boolean: true})
      } else if (window.innerWidth <= 1200 && window.innerWidth > 768) {
        this.setIsTablet({boolean: true})
      } else if (window.innerWidth <= 768) {
        this.setIsMobile({boolean: true})
      }
    },
    ...mapMutations(['showAlert']),
    ...mapActions(['setIsTablet', 'setIsMobile', 'setIsDesktop', 'fetchStudent', 'setIsLoading', 'setIsAuth', 'setShowLoginPopup']),
  },
  computed: {
    ...mapGetters(['isLoading', 'isMobile', 'isTablet', 'isDesktop', 'isAuth', 'showLoginPopup', 'alert'])
  },
  async mounted() {

    this.onResize();
    window.addEventListener('resize', this.onResize, {passive: true})

    const scriptMsg = document.createElement('script');
    scriptMsg.src = 'https://livechatv2.chat2desk.com/packs/ie-11-support.js';
    document.body.appendChild(scriptMsg)

    window.chat24_token = "d44510fff3f669d3972ddd214648953f";
    window.chat24_url = "https://livechatv2.chat2desk.com";
    window.chat24_socket_url = "wss://livechatv2.chat2desk.com/widget_ws_new";
    window.chat24_show_new_wysiwyg = "true";
    window.chat24_static_files_domain = "https://storage.chat2desk.com/";
    window.lang = "ru";
    window.fetch("".concat(window.chat24_url, "/packs/manifest.json?nocache=").concat(new Date().getTime())).then(function (res) {
      return res.json();
    }).then(function (data) {
      var chat24 = document.createElement("script");
      chat24.type = "text/javascript";
      chat24.async = true;
      chat24.src = "".concat(window.chat24_url).concat(data["application.js"]);
      document.body.appendChild(chat24);
    });


  },
  beforeDestroy() {
    if (typeof window === 'undefined') return
    window.removeEventListener('resize', this.onResize)
  },
};
</script>
