<template>
  <section class="information">
    <v-container class="information__container">
      <div class="information__list">
        <div class="information__item item" v-for="item in list" :key="item.id">
          <div class="item__img">
            <v-img :src="URL + item.image"/>
          </div>
          <div class="item__body">
            <div class="item__title" :class="{disabled: item.color === 'violet'}">{{ item.title }}</div>
            <div class="item__text" ref="description" v-html="item.description.replace(/\n/g, '<br>')"></div>
          </div>
        </div>
      </div>
    </v-container>
  </section>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  props: {
    list: {
      type: Array,
      required: true,
    }
  },
  computed: {
    ...mapGetters(['URL'])
  },
  methods: {
    createString(string) {
      let newString = ''
      for (let i = 0; i < string.length; i++) {
        newString = string.replace("\r\n", '<br>')
      }
      return newString
    }
  },
  mounted() {
    const items = this.$refs.description;
    items.forEach(item => {
      const height = item.clientHeight;
      if (height > 72) {
        item.classList.add('show-more');
        item.addEventListener('click', function () {
          item.classList.toggle('active');
        })
      }
    })
  },
  name: "ProgramList",
}
</script>

<style lang="scss" scoped>
.information {
  margin-top: 140px;

  &__container {
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    gap: 80px 50px;
  }

  &__item {
    flex: 1 1 45%;
    max-width: 710px;
  }
}

.item {
  display: flex;
  gap: 10px;
  align-items: flex-start;
  position: relative;

  .item__title {
    transform: rotate(-3.5deg);
    margin-right: auto;
  }


  &__img {
    width: 137px;
    height: 157px;
  }

  &__body {
    display: flex;
    flex-direction: column;
  }

  &__title {
    font-weight: 900;
    font-size: 28px;
    line-height: 120%;
    color: #fff;
    font-family: 'Nunito', sans-serif;
    padding: 11px 30px;
    background: linear-gradient(0deg, #D944FF, #D944FF), #FFFFFF;
    border-radius: 300px;
    margin-bottom: 20px;
    display: inline-block;

    &.disabled {
      background: #1F1F1F;
    }
  }

  &__text {
    color: #727272;
    padding-left: 30px;
    font-size: 18px;

    li {
      list-style: disc;
    }

    &.show-more {
      max-height: 72px;
      overflow: hidden;
      position: relative;

      &:after {
        content: 'Развернуть';
        font-size: 15px;
        display: block;
        width: 100%;
        height: 35px;
        position: absolute;
        left: 18px;
        bottom: 0;
        padding: 12px;
        font-weight: 500;
      }

      &:before {
        content: "";
        position: absolute;
        display: block;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 65px;
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%);
      }

      &.active {
        max-height: 100%;
        padding-bottom: 35px;

        &:before {
          display: none;
        }

        &:after {
          content: 'Свернуть'
        }
      }
    }
  }
}

@media (max-width: 1560px) {
  .item {
    max-width: 560px;

    &__img {
      width: 100px;
      height: 127px;
    }

    &__title {
      font-size: 20px;
    }

    &__text {
      font-size: 16px;
    }
  }
}

@media (max-width: 1200px) {
  .information {
    margin-top: 80px;

    &__container {
    }

    &__list {
      display: flex;
      flex-direction: column;
      gap: 50px;
    }

    &__item {
      max-width: 710px;
    }
  }
  .item {
    max-width: 100%;

    &__img {
      width: 137px;
      height: 157px;
    }

    &__title {
      font-size: 28px;
    }

    &__text {
      font-size: 18px;
    }
  }
}

@media (max-width: 992px) {
  .information {
    margin-top: 90px;

    &__container {
    }

    &__list {
      display: flex;
      flex-direction: column;
      gap: 76px;
    }

    &__item {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  .item {

    &__img {
      z-index: 2;
      top: -55px;
      left: 0;
      width: 64px;
      height: 73px;
      position: absolute;
    }

    &__title {
      font-size: 18px;
    }

    &__text {
      font-size: 14px;
      padding: 0;

      &.show-more {

        &:after {
          left: -4px;
        }
      }
    }
  }
}

@media (max-width: 475px) {
  .item {
    &__title {
      text-align: center;
      width: 100%;
    }
  }
}
</style>
