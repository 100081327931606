<template>
  <div class="popup">
    <v-card
      height="100%"

    >
      <v-card-actions>
        <v-spacer/>
        <v-btn
          icon
          @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-actions>
      <div class="popup__body">
        <div class="popup__logo">
          <img src="@/assets/svg/header-logo.svg" alt="Логотип">
        </div>
        <div class="popup__title">{{ activeTab == 1 ? 'Войти' : 'Регистрация' }}</div>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          @submit.prevent="login"
          v-if="activeTab == 1"
        >
          <v-text-field
            v-if="!isPhone"
            v-model="email"
            :rules="emailRules"
            key="name"
            label="Почта"
            required
          />
          <!--          <v-text-field-->
          <!--            v-if="isPhone"-->
          <!--            v-model="phone"-->
          <!--            :rules="phoneRules"-->
          <!--            key="phone"-->
          <!--            type="tel"-->
          <!--            label="Телефон"-->
          <!--            v-maska="'+7(###)###-##-##'"-->
          <!--            required-->
          <!--          />-->
          <v-text-field
            v-model="password"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.min]"
            :type="showPassword ? 'text' : 'password'"
            label="Пароль"
            @click:append="showPassword = !showPassword"
          />
          <!--          <div @click="isPhone = !isPhone" class="link">Использовать {{ isPhone ? 'логин' : 'телефон' }}</div>-->
          <v-btn color="primary" class="btn" block type="submit">Войти</v-btn>
          <div class="link" @click="changeTab(2)">Зарегистрироваться</div>


        </v-form>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          @submit.prevent='registerUser'
          v-if="activeTab == 2"
        >
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model.trim="registerData.firstName"
                :rules="nameRules"
                key="childName"
                label="Имя ребенка"
                required
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model.trim="registerData.lastName"
                key="childLastname"
                label="Фамилия ребенка"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model.trim="registerData.parentFirstName"
                :rules="nameRules"
                key="parentFirstName"
                label="Имя родителя"
                required
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model.trim="registerData.parentLastName"
                key="parentLastName"
                label="Фамилия родителя"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model.trim="registerData.email"
                :rules="emailRules"
                key="email"
                label="Почта"
                required
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model.trim="registerData.phone"
                key="phone"
                label="Телефон"
                v-maska="'+7(###)###-##-##'"
                :rules="phoneRules"
                required
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="registerData.plainPassword"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.registerMin]"
                :type="showPassword ? 'text' : 'password'"
                label="Пароль"
                @click:append="showPassword = !showPassword"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-btn color="primary" class="btn" block type="submit">Зарегистрироваться</v-btn>
            </v-col>
          </v-row>
          <div class="link" @click="changeTab(1)">Войти</div>


        </v-form>
      </div>
    </v-card>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import axios from "axios";

export default {
  name: "PopupLogin",
  props: {
    activeTab: {
      type: Number,
      default: 1
    }
  },
  emits: ['close'],
  data() {
    return {
      registerData: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        parentLastName: '',
        parentFirstName: '',
        plainPassword: '',
      },

      valid: true,
      name: '',
      password: '',
      email: '',
      phone: '',
      isPhone: false,
      showPassword: false,
      nameRules: [
        v => !!v || 'Имя обязательно',
        v => (v && v.length >= 2) || 'Имя должно быть длиннее 2 символов',
      ],
      phoneRules: [
        v => !!v || 'Телефон обязателен',
      ],
      emailRules: [
        v => !!v || 'E-mail обязателен',
        v => /.+@.+\..+/.test(v) || 'E-mail заполнен неверно',
      ],
      rules: {
        required: value => !!value || 'Пароль обязателен',
        min: v => v.length >= 2 || 'Пароль должен быть длиннее 3 символов',
        registerMin: v => v.length >= 4 || 'Пароль должен быть длиннее 5 символов'
      },

    }
  },
  methods: {
    ...mapActions(['setIsAuth', 'setIsLoading', 'fetchStudent', 'decodeJwt']),
    ...mapMutations(['showAlert']),
    close() {
      this.$emit('close')
    },
    changeTab(num) {
      this.$emit('changeTab', num)
    },
    async login() {
      this.$refs.form.validate();
      if (!this.$refs.form.validate()) {
        return
      }
      this.setIsLoading(true)
      try {
        const response = await axios({
          baseURL: this.URL + '/api/u/authentication_token',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          method: "POST",
          data: JSON.stringify({
            email: this.email,
            password: this.password,
          })
        })
        const token = response.data.token;
        this.decodeJwt(token)
        if (this.$route.name === 'tariffs' || this.$route.name === 'lessons') {
          this.close();
        } else {
          await this.$router.push({name: 'lessons'})
          this.close();
        }

      } catch (e) {
        console.log(e)
        this.showAlert({
          show: true,
          type: 'error',
          msg: e.response.data.message
        })
      } finally {
        this.setIsLoading(false)
      }

    },
    async registerUser() {
      this.$refs.form.validate();
      if (!this.$refs.form.validate()) {
        return
      }
      this.setIsLoading({boolean: true})
      try {
        const response = await axios({
          baseURL: this.URL + '/api/users',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          method: "POST",
          data: JSON.stringify(this.registerData)
        });
        if (response.data) {
          this.showAlert({
            show: true,
            type: 'success',
            msg: 'Вы успешно зарегистрировались! <br> Для продолжения войдите со своими данными'
          })
          this.changeTab(1)
          this.email = response.data.email;
        }
      } catch (e) {
        this.showAlert({
          show: true,
          type: 'error',
          msg: 'Во время регистрации произошла ошибка <br> Попробуйте позже или обратитесь к системному администратору'
        })
      } finally {
        this.registerData = {
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          parentLastName: '',
          parentFirstName: '',
          plainPassword: '',
        };
        this.setIsLoading(false)
      }
    }
  },
  computed: {
    ...mapGetters(['URL'])
  },

}
</script>

<style lang="scss" scoped>
.login-error {
  position: absolute;
  top: 12px;
  left: 50%;
  transform: translateX(-50%);
}

.popup {
  height: 100%;
  width: 100%;

  &__body {
    max-width: 600px;
    margin: 80px auto;
    background: #fff;
  }

  &__logo {
    text-align: center;
    margin-bottom: 24px;
  }

  &__title {
    font-weight: 700;
    font-size: 32px;
    line-height: 120%;
    color: #B374C7;
    margin-bottom: 36px;
    text-align: center;
  }

  .link {
    font-size: 16px;
    line-height: 120%;
    color: #B87DEA;
    text-decoration: underline;
    cursor: pointer;
    text-align: center;

    &:hover {
      color: #D342F8;
    }
  }

  .btn {
    display: flex;
    margin: 16px auto;
  }
}

@media(max-width: 768px) {
  .popup {
    &__body {
      max-width: 100%;
      padding: 0 20px 60px;
      margin: 20px 0 0;
    }
  }
}
</style>
