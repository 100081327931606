<template>
  <footer class="footer">
    <v-container class="footer__container">
      <div class="footer__left">
        <div class="footer__logo">
          <v-img src="@/assets/svg/footer-logo.svg"/>
        </div>
        <div class="footer__policy" style="display: none">
          <a href="#" target="_blank">Пользовательское соглашение</a>
          <a href="#" target="_blank">Политика конфиденциальности</a>
          <a href="#" target="_blank">Политика использования файлов cookie</a>
        </div>
        <div class="footer__policy">
          <span>ИП: Макарова А.М.</span>
          <span>ИНН: 344345803507</span>
          <span>ОГРНИП: 322344300022865</span>
          <span>ЮР. АДРЕС: Россия, г. Волгоград, ул. Ангарская 137/449</span>
        </div>
        <div class="footer__copyright">
          <div class="site">© Uni-kid.ru</div>
        </div>
      </div>
      <div class="footer__right">
        <nav class="menu">
          <div class="menu__list">
            <div class="menu__item">
              <router-link to="/teachers">Преподаватели</router-link>
            </div>
            <div class="menu__item">
              <router-link to="/tariffs">Тарифы</router-link>
            </div>
            <div class="menu__item">
              <router-link to="/program">Программа курса</router-link>
            </div>
            <div class="menu__item">
              <router-link to="/news">Новости</router-link>
            </div>
            <div class="menu__item">
              <a href="/files/users.pdf" target="_blank">Пользовательское соглашение</a>
            </div>
          </div>
        </nav>
        <div class="footer__contacts">
          <div class="contact">
            <div class="contact__title">Телефоны</div>
            <a href="tel:+74957974177">+7(495)797-41-77</a>
          </div>
          <div class="contact">
            <div class="contact__title">Email</div>
            <a href="mailto:help@uni-kid.ru">help@uni-kid.ru</a>
            <a href="mailto:support@uni-kid.ru">support@uni-kid.ru</a>
          </div>
        </div>
      </div>
    </v-container>
  </footer>
</template>

<script>
export default {
  name: "LandingFooter",
  data() {
    return {
    }
  }
}
</script>

<style scoped>

</style>
