<template>
  <section class="main">
    <v-container class="main__container">
      <h1 class="main__title title">
        Английский для детей
        <span>онлайн</span>
      </h1>
      <div class="main__content">
        <v-btn @click="$emit('appointment')" class="main__btn btn-circle" fab color="secondary">Записаться <br> на урок</v-btn>
        <p class="main__text">Классная комната, разработанная с&nbsp;заботой о&nbsp;ребенке и&nbsp;его успехах.
          Тематические игры, учебные материалы, домашние задания, задачи по&nbsp;интересам вовлекают ребенка в&nbsp;процесс
          обучения с&nbsp;первых минут.</p>
      </div>
      <div class="main__arrow">
        <v-img
          src="@/assets/images/main_arrow.png"
        />
      </div>
      <div class="main__sun">
        <v-img src="@/assets/images/sun.png"/>
      </div>
      <div class="main__banner">
        <v-img src="@/assets/images/main.png"/>
      </div>
    </v-container>
  </section>
</template>

<script>
export default {
  name: "SectionMain"
}
</script>

<style lang="scss" scoped>
.main {
  margin-top: 170px;

  &__container {
    position: relative;
  }

  &__title {
    max-width: 660px;
    position: relative;
    margin: 0 0 40px !important;
    text-align: left !important;

    span {
      position: absolute;
      right: 0;
      top: 0;
      font-size: 50px;
      background: #D944FF;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      width: 300px;
      height: 85px;
      border-radius: 100px;
      transform: rotate(-15deg) translate(45%, 145%);
    }
  }

  &__content {
    display: flex;
    gap: 80px;
  }

  &__text {
    max-width: 460px;
    font-size: 20px;
    line-height: 140%;
    color: #727272;
  }

  &__arrow {
    width: 138px;
    height: 138px;
    position: absolute;
    top: 200px;
    left: -120px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__sun {
    width: 289px;
    height: 289px;
    position: absolute;
    top: 20px;
    right: -170px;
    z-index: 2;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__banner {
    width: 726px;
    height: 826px;
    position: absolute;
    top: -175px;
    right: -20px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

@media (max-width: 1560px) {
  .main {
    margin-top: 120px;

    &__title {
      span {
        font-size: 36px;
        width: 200px;
        height: 65px;
        transform: rotate(-15deg) translate(-30%, 100%);
      }
    }

    &__content {
      gap: 40px;
    }


    &__arrow {
      width: 120px;
      height: 120px;
      top: 170px;
      left: -70px;
    }

    &__text {
      font-size: 18px;
    }

    &__banner {
      width: 500px;
      height: 597px;
      top: -100px;
    }

    &__sun {
      width: 180px;
      height: 180px;
      right: -90px;
    }
  }
}

@media (max-width: 1200px) {
  .main {
    margin-top: 120px;

    &__title {
      max-width: 395px;
      margin: 0 0 16px !important;

      span {
        font-size: 28px;
        width: 160px;
        height: 44px;
        transform: rotate(-15deg) translate(40%, 165%);
      }
    }

    &__content {
      gap: 20px;
    }

    &__btn {
      width: 170px;
      height: 170px;
      font-size: 14px;
    }

    &__arrow {
      top: 140px;
      width: 65px;
      height: 65px;
      left: -40px;
    }

    &__text {
      font-size: 16px;
      max-width: 350px;
    }

    &__banner {
      width: 382px;
      height: 457px;
      right: 0;
    }

    &__sun {
      width: 130px;
      height: 130px;
      right: 300px;
      top: -120px;
    }
  }
}

@media (max-width: 992px) {
  .main {
    margin-top: 80px;
    padding-bottom: 70px;

    &__title {
      max-width: 211px;

      span {
        font-size: 14px;
        width: 91px;
        height: 25px;
        transform: rotate(-15deg) translate(40%, 165%);
      }
    }

    &__content {
      gap: 20px;
      flex-direction: column-reverse;
    }

    &__arrow {
      display: none;
    }

    &__text {
      font-size: 14px;
      max-width: 100%;
      margin: 0;
    }

    &__banner {
      width: 274px;
      height: 327px;
      right: -77px;
      top: 190px;
    }

    &__sun {
      width: 85px;
      height: 85px;
      right: 0;
      top: -50px;
    }
  }
}
</style>
