<template>
  <v-app-bar
    fixed
    color="white"
    elevate-on-scroll
    hide-on-scroll
    class="header"
    :height="headerHeight"
    app
  >

    <v-container class="header__container">
      <v-toolbar-title>
        <div class="header__logo">
          <router-link to="/">
            <img src="@/assets/svg/header-logo.svg" alt="Логотип">
          </router-link>
        </div>
      </v-toolbar-title>
      <nav v-if="isDesktop" class="menu">
        <ul class="menu__list">
          <li class="menu__item">
            <router-link to="/teachers">Преподаватели</router-link>
          </li>
          <li class="menu__item">
            <router-link to="/tariffs">Тарифы</router-link>
          </li>
          <li class="menu__item">
            <router-link to="/program">Программа курса</router-link>
          </li>
          <!--          <li class="menu__item">-->
          <!--            <router-link to="/news">Новости</router-link>-->
          <!--          </li>-->
        </ul>
      </nav>
      <div v-if="isDesktop" class="account">
        <v-btn color="primary" @click="showLogin(2)" rounded large>Регистрация</v-btn>
        <v-btn color="black" @click="showLogin(1)" outlined rounded large>Войти</v-btn>
      </div>

      <div v-if="isTablet || isMobile " class="header__mobile">
        <v-btn v-if="" @click="showLogin(1)" class="header__login" color="white" icon>
          <v-icon>mdi-login-variant</v-icon>
        </v-btn>
        <v-app-bar-nav-icon @click.stop="isBurger = !isBurger" color="white" icon>
          <v-icon>mdi-menu</v-icon>
        </v-app-bar-nav-icon>
      </div>

      <v-navigation-drawer
        v-model="isBurger"
        absolute
        app
        temporary
      >
        <nav class="menu">
          <ul class="menu__list">
            <li class="menu__item">
              <router-link to="/teachers">Преподаватели</router-link>
            </li>
            <li class="menu__item">
              <router-link to="/tariffs">Тарифы</router-link>
            </li>
            <li class="menu__item">
              <router-link to="/program">Программа курса</router-link>
            </li>
            <li class="menu__item">
              <router-link to="/news">Новости</router-link>
            </li>
          </ul>
        </nav>
      </v-navigation-drawer>
    </v-container>
  </v-app-bar>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "LandingHeader",
  data() {
    return {
      isBurger: false,
    }
  },
  methods: {
    showLogin(num) {
      this.$emit('showLogin', num)
    },
  },
  computed: {
    ...mapGetters(['isMobile', 'isDesktop', 'isTablet']),
    headerHeight() {
      return this.isTablet || this.isMobile ? 74 : 104;
    }
  },

}
</script>

<style scoped>

</style>
