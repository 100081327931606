import Vue from "vue";
import VueRouter from "vue-router";
import Landing from "@/views/Landing";
import Teachers from "@/views/Teachers";
import Tariffs from "@/views/Tariffs";
import Pay from "@/views/Pay";
import Program from "@/views/Program";
import ProfilePage from "@/views/ProfilePage";
import store from "@/store";
import LessonsPage from "@/views/LessonsPage";
import VideoCall from "@/views/VideoCall.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "landing",
    component: Landing,
  },
  {
    path: "/teachers",
    name: "teachers",
    component: Teachers,
  },
  {
    path: "/tariffs",
    name: "tariffs",
    component: Tariffs,
  },
  {
    path: "/pay/:id",
    name: "pay",
    component: Pay,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/pay",
    name: "pay",
    component: Pay,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/program",
    name: "program",
    component: Program,
  },
  {
    path: "/profile",
    name: "profile",
    component: ProfilePage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/lessons",
    name: "lessons",
    component: LessonsPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/call",
    name: "videocall",
    component: VideoCall,
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  const dateNow = Date.now();
  const tokenExp = store.state.tokenExp;
  const auth = store.state.isAuth;
  if (tokenExp && dateNow >= tokenExp) {
    store.dispatch("logout").then((r) =>
      store.commit("showAlert", {
        show: true,
        type: "error",
        msg: "Время сессии истекло <br> Авторизуйтесь для продолжения",
      })
    );
    next({ name: "landing" });
  }
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.getters.isAuth) {
      next({ name: "landing" });
      store.commit("showAlert", {
        show: true,
        type: "error",
        msg: "Время сессии истекло <br> Авторизуйтесь для продолжения",
      });
    } else {
      store.dispatch("fetchUserInfo").then((r) => r);
      next();
    }
  } else {
    next(); // does not require auth, make sure to always call next()!
  }
  // обработка оплаты/редиректы из юкассы
  if (to.name === "profile") {
    if (to.query.key) {
      const key = to.query.key;
      store.commit("CHANGE_IDEMPOTENCE_KEY", key);
    } else {
      store.commit("CHANGE_IDEMPOTENCE_KEY", null);
    }
  }
  //  обработка call
  if (to.name === "videocall") {
    if (!store.state.lessonData) {
      router.push({ name: "lessons" }).then((r) =>
        store.commit("showAlert", {
          show: true,
          type: "error",
          msg: "Для доступа к уроку выберите его из таблицы",
        })
      );
    } else {
      next();
    }
  }
});
export default router;
