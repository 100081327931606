import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import ru from 'vuetify/es5/locale/ru'
Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#D944FF',
        secondary: '#B87DEA',
        third: '#eeb1fe',
        four: '#48225A',
        accent: '#8c9eff',
        error: '#b71c1c',
        black: '#1F1F1F',
      },
    },
  },
  breakpoint: {
    thresholds: {
      xs: 0,
      sm: 540,
      md: 768,
      lg: 992,
      xl: 1200
    },
  },
  lang: {
    locales: { ru },
    current: 'ru'
  },
});
