<template>
  <section class="advantages">
    <v-container class="advantages__container">
      <div class="advantages__list">
        <div class="advantage">
          <div class="advantage__img">
            <v-img contain src="@/assets/images/school.png"/>
          </div>
          <div class="advantage__body">
            <div class="advantage__title">Эффективные методики</div>
            <div class="advantage__text">Мы используем эффективный метод полного физического реагирования (TPR) и
              Кембриджскую коммуникативную методику
            </div>
          </div>
        </div>
        <div class="advantage">
          <div class="advantage__img advantage__img-horizontal">
            <v-img contain src="@/assets/images/documents.png"/>
          </div>
          <div class="advantage__body">
            <div class="advantage__title">Подтверждающие экзамены</div>
            <div class="advantage__text">По результатам прохождения курсов наши ученики сдают внутренний экзамен и
              подтверждают уровень владения языком по международному стандарту.
            </div>
          </div>
        </div>
        <div class="advantage">
          <div class="advantage__img">
            <v-img contain src="@/assets/images/certificat.png"/>
          </div>
          <div class="advantage__body">
            <div class="advantage__title">Выпускной сертификат</div>
            <div class="advantage__text">У всех учеников, успешно окончивших курс, есть возможность получения
              сертификата об обучении.
            </div>
          </div>
        </div>
      </div>
    </v-container>
  </section>
</template>

<script>
export default {
  name: "SectionAdvantages"
}
</script>

<style lang="scss" scoped>
.advantages {
  margin-top: 200px;
  &__container {
  }

  &__list {
    max-width: 1370px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    position: relative;
    z-index: 2;
  }
}

.advantage {
  flex: 0 1 27%;
  position: relative;
  display: flex;
  flex-direction: column;
  transition: transform 0.3s;
  cursor: default;
  &:nth-child(2){
    top: -60px;
  }
  &__img {
    display: flex;
    width: 137px;
    height: 157px;
    margin: 0 auto;
    position: relative;
    top: 40px;
    &-horizontal {
      width: 171px;
      height: 137px;
    }
  }

  &__body {
    background: #fff;
    box-shadow: 0px 4px 20px rgba(72, 34, 90, 0.30);
    border-radius: 4px;
    padding: 82px 30px;
    flex: 1 1 auto;
  }

  &__title {
    font-weight: 900;
    font-size: 28px;
    line-height: 120%;
    font-family: 'Nunito';
    max-width: 274px;
    margin: 0 auto 24px;
    text-align: center;
  }

  &__text {
    font-size: 18px;
    line-height: 140%;
    color: #727272;
  }
  @media (min-width: 1200px) {
    &:hover {
      transform: scale(1.2);
    }
  }
}

@media (max-width: 1560px) {
  .advantages {
    margin-top: 100px;
  }
  .advantage {
    flex: 0 1 30%;

    &__img {
      width: 130px;
      height: 110px;
      &-horizontal {
        width: 150px;
        height: 100px;
      }
    }
    &__body {
      padding: 50px 30px;
    }
    &__title {
      font-size: 24px;
    }
  }
}
@media (max-width: 1200px) {
  .advantages {
    margin-top: 80px;
  }
  .advantage {
    flex: 0 1 32%;
    &__img {
      width: 97px;
      height: 111px;
      &-horizontal {
        width: 111px;
        height: 97px;
      }
    }
    &__body {
      padding: 60px 30px;
    }
    &__title {
      font-size: 18px;
      max-width: 199px;
    }
    &__text {
      font-size: 14px;
    }
  }
}

@media (max-width: 992px) {
  .advantages {
    margin-top: 80px;
    &__list {
      flex-direction: column;
      gap: 0;
    }
  }
  .advantage {
    flex: 0 1 32%;
    &:nth-child(2){
      top: 0;
    }
    &__img {
      width: 97px;
      height: 111px;
      top: 20px;
      &-horizontal {
        width: 111px;
        height: 97px;
      }
    }

    &__body {
      padding: 60px 30px;
    }

    &__title {
      font-size: 18px;
      max-width: 199px;
    }

    &__text {
      font-size: 14px;
    }
  }
}
@media (max-width: 475px) {
  .advantages {
    margin-top: 30px;
  }
}
</style>
