<template>
  <div class="banner">
    <div class="banner__img">
      <v-img v-if="isDesktop" height="170" src="@/assets/images/animals_lg.png"/>
      <v-img v-if="isTablet" height="170" src="@/assets/images/animals_md.png"/>
      <v-img v-if="isMobile" src="@/assets/images/animals_xs.png"/>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "SectionBanner",
  computed: {
    ...mapGetters(['isTablet', 'isMobile', 'isDesktop'])
  }
}
</script>

<style lang="scss" scoped>
.banner {
  margin: 80px 0;
  @media (max-width: 1200px) {
    margin: 40px 0;
  }

  &__img {
    min-height: 170px;
  }
}
</style>
