<template>
  <v-card>
    <v-card-title>
      <v-spacer/>
      <v-btn
        icon
        @click="close"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <div class="popup">
      <div class="popup__body">
        <div class="popup__left">
          <div class="popup__title">Запишитесь на&nbspпробный урок</div>
          <div class="popup__subtitle">Заполните форму и с Вами свяжутся наши специалисты</div>
          <div class="popup__img">
            <v-img src="@/assets/images/flag.png"/>
          </div>
        </div>
        <div class="popup__right">
          <v-form ref="form" @submit.prevent="sendForm">
            <v-row>
              <v-col sm="12">
                <v-text-field
                  v-model="formData.parent"
                  :rules="nameRules"
                  key="parent"
                  label="ФИО родителя"
                  required
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="6">
                <v-text-field
                  v-model="formData.childName"
                  :rules="nameRules"
                  key="childName"
                  label="Имя ребенка"
                  required
                />
              </v-col>
              <v-col sm="6">
                <v-text-field
                  v-model="formData.childLastname"
                  :rules="nameRules"
                  key="childLastname"
                  label="Фамилия ребенка"
                  required
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="8">
                <v-text-field
                  v-model="formData.childHobbies"
                  key="childHobbies"
                  label="Увлечения"
                />
              </v-col>
              <v-col sm="4">
                <v-text-field
                  v-model="formData.childAge"
                  :rules="ageRules"
                  key="childAge"
                  label="Возраст"
                  type="number"
                  required
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12">
                <v-datetime-picker
                  label="Дата и время занятия"
                  v-model="formData.date"
                  clearText="Назад"
                  okText="Ок"
                  date-format="dd.MM.yyyy"
                  :text-field-props="textFieldProps"
                  :date-picker-props="dateProps"
                  :time-picker-props="timeProps"
                >
                  <template slot="dateIcon">
                    <v-icon>mdi-calendar</v-icon>
                  </template>
                  <template slot="timeIcon">
                    <v-icon>mdi-clock</v-icon>
                  </template>
                </v-datetime-picker>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="formData.phone"
                  :rules="phoneRules"
                  key="phone"
                  type="tel"
                  label="Телефон"
                  v-maska="'+7(###)###-##-##'"
                  required
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="formData.email"
                  :rules="emailRules"
                  key="email"
                  type="email"
                  label="Email"
                  required
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="formData.skype"
                  key="skype"
                  label="Skype"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12">
                <div class="actions">
                  <v-btn v-if="isDesktop" type="submit" class="btn-circle" fab color="secondary">Забронировать <br> урок
                  </v-btn>
                  <v-btn v-if="!isDesktop" type="submit" color="primary">Забронировать урок</v-btn>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </div>

      </div>
    </div>
  </v-card>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import axios from "axios";

export default {
  name: "PopupTrialLesson",
  data() {
    return {
      textFieldProps: {
        appendIcon: 'mdi-calendar',
      },
      dateProps: {
        firstDayOfWeek: 1,
        noTitle: true,
      },
      timeProps: {
        format: '24hr',
        noTitle: true,
        allowedMinutes: [0, 30],
        min: "8:00",
        max: "22:00"
      },
      formData: {
        parent: null,
        childName: null,
        childLastname: null,
        childAge: null,
        childHobbies: null,
        date: null,
        phone: null,
        email: null,
        skype: null,
      },
      emailRules: [
        v => !!v || 'E-mail обязателен',
        v => /.+@.+\..+/.test(v) || 'E-mail заполнен неверно',
      ],
      nameRules: [
        v => !!v || 'Заполните это поле!',
        v => (v && v.length >= 2) || 'Должно быть длиннее 2 символов',
      ],
      ageRules: [
        v => !!v || 'Заполните это поле!',
        v => (v <= 18) || 'До 18 лет',
      ],
      phoneRules: [
        v => !!v || 'Телефон обязателен',
      ],
    }
  },
  methods: {
    ...mapMutations(['showAlert']),
    ...mapActions(['setIsLoading']),
    close() {
      this.$emit('close');
    },
    async sendForm() {
      this.$refs.form.validate();
      if (!this.$refs.form.validate()) {
        return
      }
      this.setIsLoading(true)
      try {
        console.log(this.formData)
        const response = await axios({
          baseURL: this.URL + '/api/leads',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          method: "POST",
          data: JSON.stringify({
            kidName: this.formData.childName,
            kidSurname: this.formData.childLastname,
            kidHobbies: this.formData.childHobbies,
            kidAge: +this.formData.childAge,
            dateAt: this.formData.date,
            timeAt: null,
            phone: this.formData.phone,
            email: this.formData.email,
            fioParent: this.formData.parent,
            skype: this.formData.skype
          })
        });
        this.showAlert({
          show: true,
          type: 'success',
          msg: 'Ваша заявка принята! Мы свяжемся с вами в ближайшее время.'
        })
      } catch (e) {
        const error = e.response.data.detail.slice(e.response.data.detail.indexOf(':') + 2)
        this.showAlert({
          show: true,
          type: 'error',
          msg: error
        })
      } finally {
        this.setIsLoading(false)
      }

      this.formData = {
        parent: null,
        childName: null,
        childAge: null,
        childHobbies: null,
        date: null,
        phone: null,
        skype: null
      }
      this.$refs.form.reset()
    }
  },
  computed: {
    ...mapGetters(['isDesktop', 'URL'])
  },
  mounted() {
  }
}
</script>

<style lang="scss" scoped>
.popup {

}

.popup__body {
  padding: 20px 110px 50px;
  display: flex;
  gap: 65px;
}

.popup__left {
  flex: 0 0 50%;
}

.popup__title {
  font-family: 'Nunito';
  font-weight: 900;
  font-size: 48px;
  line-height: 120%;
  margin-bottom: 20px;
}

.popup__subtitle {
  font-size: 24px;
  line-height: 120%;
  color: #4C4C4C;
  margin-bottom: 70px;
}

.popup__img {
  width: 354px;
}

.popup__right {
  flex: 1 1 auto;
}

@media (max-width: 1200px) {
  .popup__body {
    padding: 20px 60px 60px;
    gap: 14px;
  }
}

@media (max-width: 992px) {
  .popup__body {
    flex-direction: column;
    padding: 0 32px 32px;
  }
  .popup__left {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .popup__title {
    font-size: 32px;
    margin-bottom: 12px;
  }
  .popup__subtitle {
    font-size: 18px;
    margin: 0;
  }
  .popup__img {
    order: -1;
    margin: 0 auto;
    width: 220px;
  }
  button {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .popup__body {
    padding: 0 12px 12px;
  }
  .popup__img {
    width: 180px;
  }
  .popup__title {
    font-size: 24px;
  }
  .popup__subtitle {
    font-size: 14px;
  }
}
</style>
