<template>
  <section class="about">
    <v-container class="about__container">
      <div class="about__card">
        <div class="about__title title title-md">
          Эффективный подход к изучению языка
        </div>
        <div class="about__list">
          <div class="about__item item" v-for="item in list" :key="item.id">
            <div class="item__img">
              <v-img width="24" height="24" src="@/assets/images/check.png"/>
            </div>
            <div class="item__text">{{ item.data }}</div>
          </div>
        </div>

        <div class="about__img">
          <v-img src="@/assets/images/about.png"/>
        </div>
      </div>
    </v-container>
    <div class="about__bg">
      <v-img height="100%" width="100%" contain src="@/assets/images/spiral.png"/>
    </div>
  </section>
</template>

<script>
export default {
  name: "SectionAbout",
  data() {
    return {
      list: [
        {
          id: 1,
          data: 'Уроки 1 на 1 с учителем',
        }, {
          id: 2,
          data: 'Учитываются особенности каждого ребенка',
        }, {
          id: 3,
          data: 'Персонализированный план обучения',
        }, {
          id: 4,
          data: 'Обратная связь после каждого урока',
        }, {
          id: 5,
          data: 'Групповые занятия с ровесниками из разных стран',
        }, {
          id: 6,
          data: 'Погружение в языковую среду',
        },
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.about {
  color: #fff;
  margin-top: 180px;
  position: relative;

  &__container {
  }

  &__card {
    padding: 150px 140px;
    background: #B87DEA;
    box-shadow: 0 12px 40px rgba(0, 0, 0, 0.15);
    border-radius: 24px;
    position: relative;
    z-index: 2;
  }

  &__title {
    margin-bottom: 50px;
    width: 720px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-width: 510px;
  }

  &__item {

  }

  &__img {
    position: absolute;
    top: -10px;
    right: 80px;
    width: 510px;
    height: 680px;
  }

  &__bg {
    width: 1058px;
    height: 965px;
    position: absolute;
    top: -450px;
    left: -370px;
  }
}

.item {
  display: flex;
  gap: 16px;
  font-size: 20px;
  align-items: flex-start;

  &__img {
    width: 24px;
  }

  &__text {
    position: relative;
    top: -2px;
  }
}

@media (max-width: 1560px) {
  .about {
    &__card {
      padding: 100px;
    }

    &__title {
      width: 520px;
    }

    &__img {
      top: -10px;
      right: 20px;
      width: 450px;
      height: 600px;
    }

    &__bg {
      width: 700px;
      height: 600px;
      top: -350px;
      left: -230px;
    }
  }
}

@media (max-width: 1200px) {
  .about {
    margin-top: 140px;

    &__card {
      padding: 60px 70px;
    }

    &__title {
      width: 520px;
    }

    &__img {
      top: -10px;
      right: 30px;
      width: 310px;
      height: 410px;
    }

    &__bg {
      width: 650px;
      height: 590px;
      top: -350px;
      left: -70px;
    }
  }
  .item {
    font-size: 14px;

    &__text {
      top: 0;
    }
  }
}

@media (max-width: 992px) {
  .about {
    &__card {
      padding: 75px 20px;
    }

    &__title {
      width: 100%;
      margin-bottom: 24px;
    }

    &__img {
      top: -100px;
      right: 30px;
      width: 110px;
      height: 150px;
    }

    &__bg {
      width: 650px;
      height: 590px;
      top: -350px;
      left: -70px;
    }
  }

}
</style>
