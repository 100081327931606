<template>
  <section class="information">
    <v-container class="information__container">
      <div class="information__list">
        <div class="information__item item">
          <div class="item__img">
            <v-img src="@/assets/images/youtube.png"/>
          </div>
          <div class="item__body">
            <div class="item__title">Индивидуальные занятия онлайн</div>
            <div class="item__text">Уроки проходят в комфортной для ребенка обстановке. Вы экономите время на поездках к
              репетитору и оффлайн-школы. Онлайн формат позволяет составить максимально удобный график.
            </div>
          </div>
        </div>
        <div class="information__item item">
          <div class="item__body">
            <div class="item__title">Уроки, которые нравятся детям</div>
            <div class="item__text">На уроках мы выбираем различные темы под интересы ребенка. Обучение проходит через
              увлекающий сюжет.
            </div>
          </div>
          <div class="item__img">
            <v-img src="@/assets/images/like.png"/>
          </div>
        </div>
        <div class="information__item item">
          <div class="item__img">
            <v-img src="@/assets/images/hands.png"/>
          </div>
          <div class="item__body">
            <div class="item__title">Спокойствие для родителей</div>
            <div class="item__text">Подберем преподавателя, составим удобный график обучения, напомним об уроках и
              проконтролируем усвоение знаний. Сопроводим Вас от отправки заявки на пробный урок до завершения полного
              прохождения курсов.
            </div>
          </div>
        </div>
        <div class="information__item item">
          <div class="item__body">
            <div class="item__title">Разговорная практика</div>
            <div class="item__text">Помогаем ребенку преодолеть языковой барьер и развиваем навык говорения. На каждом
              уроке ученик говорит более 50% времени. Практикуем разговорный английский со сверстниками в небольших
              группах.
            </div>
          </div>
          <div class="item__img">
            <v-img src="@/assets/images/speak.png"/>
          </div>
        </div>
      </div>
    </v-container>
  </section>
</template>

<script>
export default {
  name: "SectionInformation"
}
</script>

<style lang="scss" scoped>
.information {
  margin-top: 140px;

  &__container {
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    gap: 80px 50px;
  }

  &__item {
    flex: 1 1 45%;
    max-width: 710px;
  }
}

.item {
  display: flex;
  gap: 10px;
  align-items: flex-start;
  position: relative;

  &:nth-child(even) {
    .item__title {
      transform: rotate(3.5deg);
      margin-left: auto;
    }
  }

  &:nth-child(odd) {
    .item__title {
      transform: rotate(-3.5deg);
      margin-right: auto;
    }
  }

  &:nth-child(2) {
    margin-top: 120px;
  }

  &:nth-child(3) {
    top: -120px;
  }

  &__img {
    width: 137px;
    height: 157px;
  }

  &__body {
    display: flex;
    flex-direction: column;
  }

  &__title {
    font-weight: 900;
    font-size: 28px;
    line-height: 120%;
    color: #fff;
    font-family: 'Nunito';
    padding: 11px 30px;
    background: linear-gradient(0deg, #D944FF, #D944FF), #FFFFFF;
    border-radius: 300px;
    margin-bottom: 20px;
    display: inline-block;
  }

  &__text {
    color: #727272;
    padding-left: 30px;
    font-size: 18px;
  }
}

@media (max-width: 1560px) {
  .item {
    &:nth-child(2) {
      margin-top: 80px;
      margin-bottom: 30px;
    }

    &:nth-child(3) {
      top: -90px;
    }

    &__img {
      width: 100px;
      height: 127px;
    }

    &__title {
      font-size: 20px;
    }

    &__text {
      font-size: 16px;
    }
  }
}

@media (max-width: 1200px) {
  .information {
    margin-top: 80px;

    &__container {
    }

    &__list {
      display: flex;
      flex-direction: column;
      gap: 50px;
    }

    &__item {
      max-width: 710px;
      min-height: 170px;
    }
  }
  .item {
    &:nth-child(even) {
      align-self: flex-end;
    }

    &:nth-child(odd) {

    }

    &:nth-child(2) {
      margin: 0;
    }

    &:nth-child(3) {
      top: 0;
    }

    &__img {
      width: 137px;
      height: 157px;
    }

    &__title {
      font-size: 28px;
    }

    &__text {
      font-size: 18px;
    }
  }
}
@media (max-width: 992px) {
  .information {
    margin-top: 90px;

    &__container {
    }

    &__list {
      display: flex;
      flex-direction: column;
      gap: 36px;
    }

    &__item {
      flex: 0 0 100%;
      max-width: 100%;
      min-height: 170px;
    }
  }
  .item {
    &:nth-child(even) {
      align-self: flex-start;
      .item__img {
        top: -55px;
        right: 5px;
      }
    }

    &:nth-child(odd) {
      .item__img {
        top: -55px;
        left: 5px;
        z-index: 2;
      }
    }

    &:nth-child(2) {
      margin: 0;
    }

    &:nth-child(3) {
      top: 0;
    }

    &__img {
      width: 64px;
      height: 73px;
      position: absolute;
    }

    &__title {
      font-size: 18px;
    }

    &__text {
      font-size: 14px;
      padding: 0;
    }
  }
}
@media (max-width: 475px) {
  .item {
    &__title {
      text-align: center;
      width: 100%;
    }
  }
}
</style>
