<template>
  <section class="start">
    <v-container class="start__container">
      <div class="start__img">
        <v-img src="@/assets/images/learn.png"/>
      </div>
      <div class="start__flag">
        <v-img src="@/assets/images/flag.png"/>
      </div>
      <div class="start__content">
        <div class="title title-md start__title">Начни обучение с&nbspпробного урока</div>
        <div class="start__action">
          <v-btn @click="$emit('appointment')" class="btn-circle start__btn" fab color="secondary">Записаться <br> на урок</v-btn>
          <div class="badges">
            <div class="badge">Знакомство с преподавателем</div>
            <div class="badge">Определение уровня знаний</div>
            <div class="badge">Подбор программы обучения</div>
          </div>
        </div>
      </div>
    </v-container>
  </section>
</template>

<script>
export default {
  name: "SectionStart"
}
</script>

<style lang="scss" scoped>
.start {
  margin-top: 160px;

  &__container {
    position: relative;
    display: flex;
    align-items: center;
  }

  &__img {
    width: 645px;
    height: 787px;
  }

  &__flag {
    position: absolute;
    width: 413px;
    height: 356px;
    top: -90px;
    right: -120px;
  }

  &__content {
    max-width: 660px;
    margin-left: auto;
  }

  &__title {
    margin-bottom: 20px;
  }

  &__action {
    display: flex;
    gap: 50px;
    align-items: center;
  }

  &__btn {
  }
}

.badges {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.badge {
  background: rgba(238, 177, 254, 0.5);
  border-radius: 100px;
  padding: 10px 20px;
  font-size: 20px;
}

@media (max-width: 1560px) {
  .start {
    &__img {
      width: 550px;
      height: 650px;
    }

    &__content {
      max-width: 540px;
      margin-left: auto;
    }

    &__flag {
      width: 350px;
      height: 300px;
    }
  }
  .badge {
    font-size: 16px;
  }
}

@media (max-width: 1200px) {
  .start {
    &__img {
      width: 390px;
      height: 475px;
      position: relative;
      z-index: 2;
    }

    &__content {
      max-width: 450px;
      margin-left: auto;
    }

    &__title {
      margin-bottom: 20px;
      text-align: center;
    }

    &__flag {
      top: -160px;
      left: 230px;
      right: auto;
      width: 266px;
      height: 230px;
    }

    &__action {
      flex-direction: column-reverse;
    }
  }
}

@media (max-width: 992px) {
  .start {
    &__img {
      display: none;
    }

    &__content {
      max-width: 100%;
      margin: 0 auto;
    }

    &__title {
      margin-bottom: 20px;
      text-align: center;
    }

    &__flag {
      top: -160px;
      left: 50%;
      width: 165px;
      height: 145px;
      transform: translateX(-50%);
    }

    &__action {
      gap: 32px;
      flex-direction: column-reverse;
    }
  }
}
</style>
