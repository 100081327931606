<template>
  <section class="about">
    <v-container class="about__container">
      <div class="about__card">
        <div class="about__description">
          <span>● {{ item.countLessons }}</span>
          <span>● {{ item.durationLesson }} </span>
          <span>● {{ item.durationProgram }}</span>
        </div>
        <div class="about__title title title-md">
          {{ item.name }}
        </div>
        <div class="about__list">
          <div class="about__item item" v-for="(descr,i) in item.descriptionPoints" :key="i">
            <div class="item__img">
              <v-img width="24" height="24" src="@/assets/images/check.png"/>
            </div>
            <div class="item__text">{{ descr }}</div>
          </div>
        </div>

        <div class="about__img">
          <v-img :src="URL+item.image"/>
        </div>
      </div>
    </v-container>
  </section>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "ProgramHeader",
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['URL'])
  }
}
</script>

<style lang="scss" scoped>
.about {
  color: #fff;
  margin-top: 75px;
  position: relative;
  margin-bottom: 120px;

  &__description {
    font-size: 20px;
    display: flex;
    gap: 30px;
  }

  &__card {
    padding: 95px 150px;
    background: #B87DEA;
    //box-shadow: 0 12px 40px rgba(0, 0, 0, 0.15);
    border-radius: 24px;
    position: relative;
    z-index: 2;
  }

  &__title {
    margin: 24px 0 50px;
    width: 720px;
    text-align: left;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-width: 600px;
  }

  &__item {

  }

  &__img {
    position: absolute;
    top: -5px;
    right: 120px;
    width: 430px;
    height: 575px;
  }

}

.item {
  display: flex;
  gap: 16px;
  font-size: 20px;
  align-items: flex-start;

  &__img {
    width: 24px;
  }

  &__text {
    position: relative;
    top: -2px;
  }
}

@media (max-width: 1560px) {
  .about {
    &__card {
      padding: 100px;
    }

    &__title {
      width: 520px;
    }

    &__img {
      top: -10px;
      right: 20px;
      width: 229px;
      height: 306px;
    }

  }
}

@media (max-width: 1200px) {
  .about {
    margin: 80px 0;

    &__card {
      padding: 60px 70px;
    }

    &__title {
      width: 520px;
      margin: 24px 0;
    }

    &__list {
      max-width: 520px;
    }
  }
  .item {
    font-size: 14px;

    &__text {
      top: 0;
    }
  }
}

@media (max-width: 992px) {
  .about {
    &__card {
      padding: 50px 20px;
    }

    &__description {
      flex-direction: column;
      font-size: 14px;
      gap: 8px;
    }

    &__title {
      width: 100%;
    }

    &__img {
      top: -30px;
      right: 20px;
      width: 111px;
      height: 148px;
    }
  }

}
</style>
