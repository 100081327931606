<template>
  <div class="page">
    <section-main @appointment="showTrialPopup = true"/>
    <section-advantages/>
    <section-about/>
    <section-information/>
    <section-start @appointment="showTrialPopup = true"/>
    <section-banner/>
    <v-dialog
      v-model="showTrialPopup"
      max-width="1200"
      :fullscreen="!isDesktop"
      content-class="no-scrollbar"
    >
      <popup-trial-lesson @close="showTrialPopup = false"/>
    </v-dialog>
  </div>
</template>

<script>
import SectionMain from "@/components/mainPage/SectionMain";
import SectionAdvantages from "@/components/mainPage/SectionAdvantages";
import SectionAbout from "@/components/mainPage/SectionAbout";
import SectionInformation from "@/components/mainPage/SectionInformation";
import SectionStart from "@/components/mainPage/SectionStart";
import SectionBanner from "@/components/mainPage/SectionBanner";
import PopupTrialLesson from "@/components/popups/PopupTrialLesson";
import {mapGetters} from "vuex";

export default {
  name: 'landing',
  components: {
    PopupTrialLesson,
    SectionBanner, SectionStart, SectionInformation, SectionAbout, SectionAdvantages, SectionMain
  },
  data() {
    return {
      showTrialPopup: false,

    }
  },
  methods: {},
  computed: {
    ...mapGetters(['isDesktop'])
  },
}
</script>

<style lang="scss" scoped>


</style>
