<template>
  <div class="page">
    <v-container>
      <h1 class="title">Программа курса</h1>
      <v-tabs
        v-model="tab"
        grow
        show-arrows
      >
        <v-tab
          v-for="item in items"
          :key="item.id"
        >
          {{ item.name }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item
          v-for="item in items"
          :key="item.id"
        >
          <program-header :item="item"/>
          <program-list :list="item.items"/>
        </v-tab-item>
      </v-tabs-items>
    </v-container>
  </div>
</template>

<script>
import SectionAbout from "@/components/mainPage/SectionAbout";
import ProgramHeader from "@/components/programPage/ProgramHeader";
import ProgramList from "@/components/programPage/ProgramList";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "Program",
  components: {ProgramList, ProgramHeader, SectionAbout},
  data() {
    return {
      tab: null,
      items: [],
    }
  },
  methods: {
    async getPrograms() {
      this.setIsLoading(true)
      try {
        const response = await fetch(`${this.URL}/api/programs`);
        const data = await response.json();
        this.items = data;
      } catch (e) {
        console.log(e)
      } finally {
        this.setIsLoading(false)
      }
    },
    ...mapActions(['setIsLoading'])
  },
  computed: {
    ...mapGetters(['URL'])
  },
  beforeMount() {
    this.getPrograms();
  }
}
</script>

<style lang="scss" scoped>
.page {
  margin-bottom: 140px;
}
</style>
