<template>
  <div class="page">
    <v-container class="page__container">
      <div class="page__bg">
        <v-img src="@/assets/images/flag.png"/>
      </div>
      <h1 class="title">Оплата</h1>
      <div class="pay">
        <div class="pay__row">
          <div class="pay__title">Выбранный тариф</div>
          <div class="tariff">
            <div class="tariff__plan">
              <span>● {{ tariff.type === 'premium' ? 'Премиум' : 'Стандарт' }}</span>
              <span>● {{ tariff.duration }} минут</span>
              <span>● {{ tariff.lessons }} занятий</span>
            </div>
          </div>
        </div>
        <div class="pay__row">
          <div class="pay__title">Способ оплаты</div>
          <div class="pay__types">
            <div class="type">
              <div class="type__img">
                <v-img src="@/assets/images/mastercard.png"/>
              </div>
            </div>
            <div class="type">
              <div class="type__img">
                <v-img src="@/assets/images/visa.png"/>
              </div>
            </div>
            <div class="type">
              <div class="type__img">
                <v-img src="@/assets/images/union.png"/>
              </div>
            </div>
            <div class="type">
              <div class="type__img">
                <v-img src="@/assets/images/mir.png"/>
              </div>
            </div>
          </div>
        </div>
        <div v-if="discount" class="pay__row">
          <div class="pay__title">Промокод</div>
          <div class="promo">
            <v-text-field color="primary" rounded filled label="Промокод"></v-text-field>
            <v-btn class="promo__btn" height="56" color="primary" rounded large>Применить</v-btn>
          </div>
        </div>
        <div class="final">
          <div v-if="discount" class="final__row sale">
            <div class="final__title">Скидка:</div>
            <div class="final__count">1000 ₽</div>
          </div>
          <div class="final__row">
            <div class="final__title">К оплате:</div>
            <div class="final__count">{{ tariff.price.toLocaleString('ru') }} ₽</div>
          </div>
          <v-btn @click="pay" large height="76" class="final__btn" rounded color="secondary">Оплатить</v-btn>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import axios from "axios";

export default {
  name: "Pay",
  data() {
    return {
      tariffId: 1,
      tariff: {},
      discount: false,
    }

  },
  methods: {
    async getTariff() {
      this.setIsLoading(true)
      this.tariffId = this.$route.params.id;
      if (this.$route.params.id) {
        this.tariffId = this.$route.params.id;
      } else {
        await this.$router.push({name: 'tariffs'})
      }
      try {
        const response = await fetch(`${this.URL}/api/tariffs/${this.tariffId}`);
        this.tariff = await response.json();
      } catch (e) {
        console.log(e)
      } finally {
        this.setIsLoading(true)
      }
    },
    async pay() {
      this.setIsLoading(true)
      try {
        const response = await axios({
          baseURL: `${this.URL}/api/u/payments`,
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          method: 'POST',
          data: JSON.stringify({
            tariffs: `/api/tariffs/${this.$route.params.id}`,
            promocode: null,
          })
        })
        console.log(response.data)

      } catch (e) {
        console.log(e)
      } finally {
        this.setIsLoading(false)
      }
    },
    ...mapActions(['setIsLoading'])
  },
  computed: {
    ...mapGetters(['URL'])
  },
  beforeMount() {
    this.getTariff();
  }

}
</script>

<style lang="scss" scoped>
.page {
  &__container {
    position: relative;
  }

  &__bg {
    width: 530px;
    height: 459px;
    position: absolute;
    top: 50%;
    right: -200px;
    transform: translateY(-50%);
  }
}

.pay {
  display: flex;
  flex-direction: column;
  gap: 45px;
  margin-bottom: 80px;

  &__row {
  }

  &__title {
    font-family: 'Nunito';
    font-weight: 900;
    font-size: 24px;
    margin-bottom: 32px;
  }

  &__types {
    display: flex;
    gap: 20px;

    .type {
      width: 230px;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid rgba(0, 0, 0, 0.3);
      border-radius: 8px;

      &__img {
        width: 80px;
        height: 80px;
      }
    }
  }

  .promo {
    display: flex;
    width: 490px;

    &__btn {
      margin-left: -60px;
    }
  }

  .final {
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-top: 80px;
    padding-top: 40px;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    width: 1000px;

    &__row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 380px;

      &.sale {
        .final__title {
          color: #111729;
          opacity: 0.5;
        }

        .final__count {
          font-size: 24px;
          color: #111729;
          opacity: 0.5;
        }
      }
    }

    &__title {
      font-family: 'Nunito';
      font-weight: 900;
      font-size: 24px;
    }

    &__count {
      font-family: 'Nunito';
      font-weight: 900;
      font-size: 32px;
      color: #B87DEA;
    }

    &__btn {
      width: 377px;
      border-radius: 40px !important;
    }
  }
}

.tariff {
  background: #fff;
  box-shadow: 0 4px 40px rgba(48, 48, 48, 0.3);
  border-radius: 20px;
  padding: 50px 80px;
  width: 622px;

  &__title {
    font-family: 'Nunito';
    font-weight: 900;
    font-size: 30px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 24px;
  }

  &__plan {
    display: flex;
    justify-content: space-between;
    gap: 5px;

    span {
      font-size: 20px;
      color: #727272;
      flex: 0 0 30%;
    }
  }
}

@media (max-width: 1560px) {
  .page {
    &__bg {
      top: 100px;
      transform: translateY(0);
    }
  }
}

@media (max-width: 1200px) {
  .page {
    &__bg {
      width: 206px;
      height: 170px;
      top: 30px;
      right: 0;
    }
  }
  .pay {
    .final {
      margin-top: 30px;
      width: 100%;
    }
  }
}

@media (max-width: 992px) {
  .page {
    &__bg {
      display: none;
    }
  }
  .pay {
    gap: 24px;

    &__title {
      font-size: 18px;
      margin-bottom: 16px;
    }

    .final {
      margin-top: 30px;
      width: 100%;

      &__btn {
        width: 100%;
      }
    }
  }
  .tariff {
    &__title {
      font-size: 24px;
    }

    &__plan {
      flex-direction: column;
      align-items: center;

      span {
        font-size: 16px;
      }
    }
  }
}

@media (max-width: 768px) {
  .page {
    &__bg {
      width: 206px;
      height: 170px;
      top: 30px;
      right: 0;
    }
  }
  .tariff {
    width: 100%;
  }
  .pay {
    &__types {
      .type {
        height: 60px;

        &__img {
          width: 40px;
          height: 40px;
        }
      }
    }

    .final {
      margin-top: 0;

      &__row {
        width: 100%;
      }
    }

    .promo {
      width: 100%;

      &__btn {
        font-size: 14px !important;
      }
    }
  }
}
</style>
