<template>
  <div class="popup">
    <v-card>
      <v-card-title>
        <v-spacer/>
        <v-btn
          icon
          @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <div class="popup__body">
        <div class="popup__left">
          <div class="popup__img">
            <img :src="URL+teacher.photo"/>
          </div>
          <div @click="showVideo = true" v-if="teacher.videoPostcard" class="popup__play play">
            <div class="play__img">
              <v-img src="@/assets/images/youtube.png"/>
            </div>
            <div class="play__btn">
              Видеоприветствие
            </div>
          </div>
        </div>
        <div class="popup__right">
          <div class="name">{{ teacher.fullName }}</div>
          <div class="list">
            <div class="item">
              <span>Говорю:</span>
              {{ getString(teacher.speak) }}
            </div>
            <div class="item">
              <span>Обучаю:</span>
              {{ teacher.experience }}
            </div>
            <div class="item">
              <span>Специализация:</span>
              {{ getString(teacher.specialization) }}
            </div>
          </div>
          <div class="actions">
            <v-btn @click="showTrialPopup = true" v-if="isDesktop" class="btn-circle" fab color="secondary">
              Забронировать <br> урок
            </v-btn>
            <v-btn @click="showTrialPopup = true" v-if="!isDesktop" color="primary">Забронировать урок</v-btn>
          </div>
        </div>
      </div>
    </v-card>
    <div v-if="showVideo" class="popup video">
      <div class="popup__close" @click="showVideo = false">
        <svg width="42" height="42" viewBox="0 0 42 42" fill="white" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M28.4931 14.7039L26.9839 13.2261L21.0005 19.0848L15.017 13.2261L13.5078 14.7039L19.4912 20.5626L13.5078 26.4214L15.017 27.8992L21.0005 22.0404L26.9839 27.8992L28.4931 26.4214L22.5097 20.5626L28.4931 14.7039Z"
                fill="white"/>
        </svg>
      </div>
      <div class="popup__body">
        <video :src="URL+teacher.videoPostcard" controls></video>
      </div>
    </div>
    <v-dialog
      v-model="showTrialPopup"
      max-width="1200"
      :fullscreen="!isDesktop"
      content-class="no-scrollbar"
    >
      <popup-trial-lesson @close="showTrialPopup = false"/>
    </v-dialog>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import PopupTrialLesson from "@/components/popups/PopupTrialLesson";

export default {
  name: "PopupTeacher",
  components: {PopupTrialLesson},
  props: {
    teacher: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      showVideo: false,
      showTrialPopup: false,
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    getString(item) {
      let string = ''
      for (let itemElement in item) {
        string += `${item[itemElement]} `
      }
      return string
    }
  },
  computed: {
    ...mapGetters(['isDesktop', 'URL'])
  },
  mounted() {
    console.log(this.teacher)
  }
}
</script>

<style lang="scss" scoped>

.popup {
  &.video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 33;
  }

  &__body {
    display: flex;
    gap: 60px;
    padding: 0 120px 80px;
    position: relative;

    video {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
      z-index: 100;
    }
  }

  &__close {
    width: 42px;
    height: 42px;
    position: absolute;
    top: 24px;
    right: 24px;
    z-index: 300;
  }

  &__left {
    position: relative;
    margin-top: -100px;
  }

  &__img {
    width: 365px;
    height: 512px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0px 4px 20px rgba(72, 34, 90, 0.15);
    position: relative;
    padding-top: 80%;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__play {
  }

  &__right {
  }

  .name {
    font-family: 'Nunito';
    font-weight: 900;
    font-size: 48px;
    line-height: 120%;
    margin-bottom: 48px;
  }

  .list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    font-size: 24px;
    margin-bottom: 10px;
  }

  .item {
    display: flex;
    gap: 16px;

    span {
      flex: 0 0 210px;
      color: #9C9C9C;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    gap: 70px;
  }

  .btn-circle {
  }

  .price {
    font-weight: 700;
    font-size: 32px;
  }
}

.play {
  position: relative;
  text-align: center;
  margin-top: -70px;

  &__img {
    width: 96px;
    height: 110px;
    position: relative;
    top: 7px;
    z-index: 2;
  }

  &__btn {
    background: linear-gradient(0deg, #D944FF, #D944FF), #FFFFFF;
    border-radius: 300px;
    transform: rotate(1.5deg);
    font-family: 'Nunito';
    line-height: 1;
    font-weight: 900;
    font-size: 28px;
    color: #fff;
    padding: 10px 30px;
    display: inline-block;
  }
}

@media (max-width: 1200px) {
  .popup {
    &__body {
      gap: 30px;
      padding: 0 30px 60px;
    }

    &__left {
      position: relative;
      margin-top: 0;
    }

    &__img {
      width: 290px;
      height: 407px;
    }
  }

  .play {
    &__btn {
      font-size: 18px;
    }
  }

}

@media (max-width: 992px) {
  .popup {
    &__body {

    }

    &__left {
    }

    &__img {
      width: 290px;
      height: 407px;
    }

    .name {
      font-size: 34px;
      margin-bottom: 24px;
    }

    .list {
      font-size: 18px;
    }

    .item {
      span {
        flex: 0 0 160px;
      }
    }

    .btn-circle {
      width: 171px !important;
      height: 171px !important;
    }

    .price {
      font-size: 18px;
    }

    .actions {
      gap: 16px;
    }
  }

  .play {
    &__btn {
      font-size: 18px;
    }
  }

}

@media (max-width: 768px) {
  .popup {
    &__body {
      flex-direction: column;
    }

    &__left {
      display: flex;
      position: relative;
    }

    .name {
      font-size: 28px;
    }

    .list {
      font-size: 14px;
    }

    .item {
      span {
        flex: 0 0 120px;
      }
    }

    .price {
      font-size: 24px;
    }

    .actions {
      flex-direction: column-reverse;
      align-items: flex-start;
    }
  }

  .play {
    position: absolute;
    bottom: 0;
    right: 0;
    text-align: left;
    align-self: flex-end;

    &__img {
      margin-left: auto;
    }

    &__btn {
      font-size: 16px;
    }
  }
}

@media (max-width: 540px) {
  .popup {
    &__img {
      width: 55%;
      height: auto;
    }

    &__body {
      padding: 0 15px 30px;
    }
  }
  .play {
    &__img {
      width: 77px;
      height: 89px;
    }

    &__btn {
      width: 180px;
      text-align: center;
    }
  }
}
</style>
